















































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import compareCard from '@/components/CompareCard/index.vue';
import { touristFlowRatio } from '@/utils/echarts/echarts';
import countUpFn from '@/utils/countUp';
import { businessCore } from '@/services/api';
import tdesignFooter from '@/components/GlobalFooter/index.vue';
import portraitPrivcar from '@/components/portraitPrivcar/index.vue';
import { getContainer } from '@/components';

@Component({
  components: {
    compareCard,
    tdesignFooter,
    portraitPrivcar,
  },
})
export default class Core extends Vue {
  $dayjs: any;
  compareDate = '较昨日';
  compareTips = '无法与昨日对比';
  xtips = '日';
  lastdata = 'day';
  duration = this.$store.state.mall.duration;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  mall_id = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  displayDate = '';
  slotEcharts = true;
  slotcontent = true;
  show = true;
  pastDate = '';
  totalTourist = {};
  firstData = [''];
  secondData = [''];
  excelArr: any[] = [];
  containerHeight = '0';
  echartsHeight = `${getContainer() - 377}px`;
  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  @Watch('$store.state.mall', { immediate: false, deep: true })
  mallIdChange(): void {
    this.show = true;
    this.getBusinessCoreInfo(
      this.$store.state.mall.mallId,
      this.$store.state.mall.date,
    );
    if (this.$store.state.mall.duration === 'd') {
      this.compareDate = '较昨日';
      this.compareTips = '无法与昨日对比';
      this.xtips = '日';
    } else if (this.$store.state.mall.duration === 'xw') {
      this.compareDate = '较上周';
      this.compareTips = '无法与上周对比';
      this.xtips = '周';
    } else if (this.$store.state.mall.duration === 'xm') {
      this.compareDate = '较上月';
      this.compareTips = '无法与上月对比';
      this.xtips = '月';
    }
  }
  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const { mall } = this.$store.state;
    const {
      duration,
    } = mall;
    const datas = [];
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      if (Object.values((this.excelArr as any)?.data)) {
        Object.values((this.excelArr as any).data).forEach((i: any) => {
          // eslint-disable-next-line no-param-reassign
          switch (duration) {
            case  'd':
              return;
            case 'xw':
              // eslint-disable-next-line no-param-reassign
              i.time = `${this.$dayjs(i.time).subtract(6, 'day')
                .format('YYYY-MM-DD')}~${i.time}`;
              return;
            case  'xm':
              // eslint-disable-next-line no-param-reassign
              i.time = this.$dayjs(i.time).format('YYYY-MM');
              return;
          }
        });
      }
      datas.push({
        sheetData:
          (this.excelArr as any).data
          && Object.values((this.excelArr as any).data)
            ? Object.values((this.excelArr as any).data)
            : [],
        sheetName: '客流数据',
        sheetMultiHeader: [
          ['数据项:商圈覆盖-核心商圈'],
        ],
        sheetFilter: [
          'time',
          'pop_ratio_9',
          'pop_num_9',
          'pop_base_9',
          'percent_9',
          'pop_ratio_25',
          'pop_num_25',
          'pop_base_25',
          'percent_25',
        ],
        sheetHeader: [
          '日期',
          '9平方公里客流总数占比',
          '9平方公里到访人数',
          '9平方公里居住人口总数',
          '9平方公里市场渗透率',
          '25平方公里客流总数占比',
          '25平方公里到访人数',
          '25平方公里居住人口总数',
          '25平方公里市场渗透率',
        ],
        columnWidths: [8, 12, 12, 12, 12, 12, 12, 12, 12],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
  async mounted(): Promise<void> {
    const hg = getContainer();
    this.containerHeight = `${hg - 32}px`;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getBusinessCoreInfo(id: number, nowdate: string): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.show = true;
    const date = this.numOfday(nowdate);
    const params = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      mall_id: id,
      duration: this.$store.state.mall.duration,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      date_ary: date,
    };
    const res = await businessCore(params);
    this.excelArr = res;
    this.firstData = [];
    this.secondData = [];
    Object.keys((res as any).data).forEach((i) => {
      if ((res as any).data[i] as any) {
        this.firstData.push(((res as any).data[i] as any).pop_ratio_9);
        this.secondData.push(((res as any).data[i] as any).pop_ratio_25);
      }
    });
    this.firstData.forEach((i: any, index: number) => {
      if (i === -1) {
        this.$set(this.firstData, index, 0);
      }
    });
    this.secondData.forEach((i: any, index: number) => {
      if (i === -1) {
        this.$set(this.secondData, index, 0);
      }
    });
    this.totalTourist = touristFlowRatio(
      '9平方公里内客流总数',
      '25平方公里内客流总数',
      Object.keys((res as any).data),
      this.firstData,
      this.secondData,
      this.xtips,
    );
    if ((res as any).status === 0 && (res as any).data) {
      this.displayDate = (res as any).data[nowdate];
      this.pastDate = (res as any).data[
        Object.keys(res.data)[Object.keys(res.data).length - 2]
      ];
    } else {
      this.displayDate = '';
      this.pastDate = '';
    }
    countUpFn(
      'box2',
      (this.displayDate as any)
        && (this.displayDate as any).pop_num_9
        && (this.displayDate as any).pop_num_9 !== -1
        ? (this.displayDate as any).pop_num_9
        : '暂无数据',
    );
    countUpFn(
      'box3',
      (this.displayDate as any)
        && (this.displayDate as any).pop_base_9
        && (this.displayDate as any).pop_base_9 !== -1
        ? (this.displayDate as any).pop_base_9
        : '暂无数据',
    );
    countUpFn(
      'box7',
      (this.displayDate as any) && (this.displayDate as any).pop_num_25
        ? (this.displayDate as any).pop_num_25
        : '暂无数据',
    );
    countUpFn(
      'box8',
      (this.displayDate as any) && (this.displayDate as any).pop_base_25
        ? (this.displayDate as any).pop_base_25
        : '暂无数据',
    );
    countUpFn(
      'box9',
      (this.displayDate as any) && (this.displayDate as any).pop_ratio_9
        ? (this.displayDate as any).pop_ratio_9
        : 0,
    );
    this.show = false;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }
  numOfday(params: string): any {
    const arr = [];
    if (this.$store.state.mall.duration === 'd') {
      this.lastdata = 'day';
      let begin = this.$dayjs(params)
        .subtract(30, 'day')
        .format('YYYY-MM-DD');
      const end = this.$dayjs(params).format('YYYY-MM-DD');
      while (begin <= end) {
        arr.push(begin);
        begin = this.$dayjs(begin)
          .add(1, 'day')
          .format('YYYY-MM-DD');
      }
      return arr;
    }
    if (this.$store.state.mall.duration === 'xw') {
      this.lastdata = 'week';
      let begin = this.$dayjs(params)
        .subtract(7, 'week')
        .format('YYYY-MM-DD');
      const end = this.$dayjs(params).format('YYYY-MM-DD');
      while (begin <= end) {
        arr.push(begin);
        begin = this.$dayjs(begin)
          .add(1, 'week')
          .format('YYYY-MM-DD');
      }
      return arr;
    }
    if (this.$store.state.mall.duration === 'xm') {
      this.lastdata = 'month';
      let begin = this.$dayjs(params)
        .subtract(7, 'month')
        .format('YYYY-MM-DD');
      const end = this.$dayjs(params).format('YYYY-MM-DD');
      while (begin <= end) {
        arr.push(begin);
        begin = this.$dayjs(begin)
          .add(1, 'month')
          .format('YYYY-MM-DD');
      }
      return arr;
    }
  }
}
