var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"core mall-content"},[_c('compareCard',{attrs:{"padding":"24px 44px","cardHeight":_vm.containerHeight,"title":"客流数据","slotEcharts":"","slotcontent":"","echartsHeight":"100%","topHeight":"161px","toppadding":"24px 0 0","echartspadding":"51px 0 0 0","spin":"","showSpin":_vm.show},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"content"},[_c('div',{staticClass:"ratio"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"vertical-blue"},[_vm._v("|")]),_c('div',{staticClass:"ratio-title"},[_vm._v("9平方公里内")]),_c('div',[_c('Tooltip',{attrs:{"placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 以区域为中心，周边9平方公里的格子内，客流占比等情况 ")]),_c('Icon',{attrs:{"type":"md-help-circle"}})],1)],1)]),_c('div',{staticClass:"ratio-content"},[_c('div',{staticClass:"ratio-line"},[_c('div',[_c('div',[_vm._v("客流总数占比")]),_c('div',{attrs:{"id":"box1"}},[_vm._v(" "+_vm._s(_vm.displayDate && _vm.displayDate.pop_ratio_9 && _vm.displayDate.pop_ratio_9 !== -1 ? _vm.displayDate.pop_ratio_9 + '%' : '暂无数据')+" ")]),_c('div',[(
                      _vm.pastDate &&
                        _vm.pastDate.pop_ratio_9 &&
                        _vm.displayDate &&
                        _vm.displayDate.pop_ratio_9 &&
                        _vm.displayDate.pop_ratio_9 !== -1 &&
                        !isNaN(_vm.displayDate.pop_ratio_9 / _vm.pastDate.pop_ratio_9)
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.pastDate &&
                        _vm.pastDate.pop_ratio_9 &&
                        _vm.pastDate.pop_ratio_9 !== -1 &&
                        _vm.displayDate &&
                        _vm.displayDate.pop_ratio_9 &&
                        _vm.displayDate.pop_ratio_9 !== -1 &&
                        !isNaN(_vm.displayDate.pop_ratio_9 / _vm.pastDate.pop_ratio_9)
                    )?_c('div',{class:_vm.displayDate.pop_ratio_9 - _vm.pastDate.pop_ratio_9 > 0
                        ? 'green'
                        : 'red'},[_vm._v(" "+_vm._s(( (_vm.displayDate.pop_ratio_9 / _vm.pastDate.pop_ratio_9 - 1) * 100 ).toFixed(2) + '%')+" ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_ratio_9 &&
                        _vm.displayDate.pop_ratio_9 !== -1 &&
                        _vm.pastDate.pop_ratio_9 &&
                        _vm.pastDate.pop_ratio_9 !== -1 &&
                        !isNaN(_vm.displayDate.pop_ratio_9 / _vm.pastDate.pop_ratio_9)
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.pop_ratio_9 - _vm.pastDate.pop_ratio_9 > 0
                          ? 'green'
                          : 'red',attrs:{"type":_vm.displayDate.pop_ratio_9 - _vm.pastDate.pop_ratio_9 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])]),_c('div',[_c('div',[_vm._v("到访人数")]),(
                    _vm.displayDate &&
                      _vm.displayDate.pop_num_9 &&
                      _vm.displayDate.pop_num_9 !== -1
                  )?_c('div',{attrs:{"id":"box2"}},[_vm._v(" "+_vm._s(_vm.displayDate.pop_num_9)+" ")]):_c('div',[_vm._v("暂无数据")]),_c('div',[(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_num_9 &&
                        _vm.pastDate.pop_num_9 &&
                        _vm.displayDate.pop_num_9 !== -1 &&
                        _vm.pastDate.pop_num_9 !== -1 &&
                        !isNaN((_vm.displayDate.pop_num_9 / _vm.pastDate.pop_num_9 - 1))
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_num_9 &&
                        _vm.pastDate.pop_num_9 &&
                        _vm.displayDate.pop_num_9 !== -1 &&
                        _vm.pastDate.pop_num_9 !== -1 &&
                        !isNaN((_vm.displayDate.pop_num_9 / _vm.pastDate.pop_num_9 - 1))
                    )?_c('div',{class:_vm.displayDate.pop_num_9 - _vm.pastDate.pop_num_9 < 0
                        ? 'red'
                        : 'green'},[_vm._v(" "+_vm._s(( (_vm.displayDate.pop_num_9 / _vm.pastDate.pop_num_9 - 1) * 100 ).toFixed(2))+"% ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_num_9 &&
                        _vm.pastDate.pop_num_9 &&
                        _vm.displayDate.pop_num_9 !== -1 &&
                        _vm.pastDate.pop_num_9 !== -1 &&
                        !isNaN((_vm.displayDate.pop_num_9 / _vm.pastDate.pop_num_9 - 1))
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.pop_num_9 - _vm.pastDate.pop_num_9 < 0
                          ? 'red'
                          : 'green',attrs:{"type":_vm.displayDate.pop_num_9 - _vm.pastDate.pop_num_9 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])]),_c('div',[_c('div',[_vm._v("居住人口总数")]),(
                    _vm.displayDate &&
                      _vm.displayDate.pop_base_9 &&
                      _vm.displayDate.pop_base_9 !== -1
                  )?_c('div',{attrs:{"id":"box3"}},[_vm._v(" "+_vm._s(_vm.displayDate.pop_base_9)+" ")]):_c('div',[_vm._v("暂无数据")]),_c('div',[(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_base_9 &&
                        _vm.pastDate.pop_base_9 &&
                        _vm.displayDate.pop_base_9 !== -1 &&
                        _vm.pastDate.pop_base_9 !== -1 &&
                        !isNaN (_vm.displayDate.pop_base_9 / _vm.pastDate.pop_base_9 - 1)
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_base_9 &&
                        _vm.pastDate.pop_base_9 &&
                        _vm.displayDate.pop_base_9 !== -1 &&
                        _vm.pastDate.pop_base_9 !== -1 &&
                        !isNaN (_vm.displayDate.pop_base_9 / _vm.pastDate.pop_base_9 - 1)
                    )?_c('div',{class:_vm.displayDate.pop_base_9 - _vm.pastDate.pop_base_9 < 0
                        ? 'red'
                        : 'green'},[_vm._v(" "+_vm._s(( (_vm.displayDate.pop_base_9 / _vm.pastDate.pop_base_9 - 1) * 100 ).toFixed(2))+"% ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_base_9 &&
                        _vm.pastDate.pop_base_9 &&
                        _vm.displayDate.pop_base_9 !== -1 &&
                        _vm.pastDate.pop_base_9 !== -1 &&
                        !isNaN (_vm.displayDate.pop_base_9 / _vm.pastDate.pop_base_9 - 1)
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.pop_base_9 - _vm.pastDate.pop_base_9 < 0
                          ? 'red'
                          : 'green',attrs:{"type":_vm.displayDate.pop_base_9 - _vm.pastDate.pop_base_9 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])]),_c('div',[_c('div',[_vm._v("市场渗透率")]),_c('div',[_vm._v(" "+_vm._s(_vm.displayDate && _vm.displayDate.percent_9 && _vm.displayDate.percent_9 !== -1 ? _vm.displayDate.percent_9 + '%' : '暂无数据')+" ")]),_c('div',[(
                      _vm.displayDate &&
                        _vm.displayDate.percent_9 &&
                        _vm.displayDate.percent_9 !== -1 &&
                        _vm.pastDate &&
                        _vm.pastDate.percent_9 &&
                        _vm.pastDate.percent_9 !== -1 &&
                        !isNaN(_vm.displayDate.percent_9 / _vm.pastDate.percent_9 - 1)
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.displayDate &&
                        _vm.displayDate.percent_9 &&
                        _vm.displayDate.percent_9 !== -1 &&
                        _vm.pastDate &&
                        _vm.pastDate.percent_9 &&
                        _vm.pastDate.percent_9 !== -1 &&
                        !isNaN(_vm.displayDate.percent_9 / _vm.pastDate.percent_9 - 1)
                    )?_c('div',{class:_vm.displayDate.percent_9 - _vm.pastDate.percent_9 < 0
                        ? 'red'
                        : 'green'},[_vm._v(" "+_vm._s(( (_vm.displayDate.percent_9 / _vm.pastDate.percent_9 - 1) * 100 ).toFixed(2) + '%')+" ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.displayDate.percent_9 &&
                        _vm.displayDate.percent_9 !== -1 &&
                        _vm.pastDate &&
                        _vm.pastDate.percent_9 &&
                        _vm.pastDate.percent_9 !== -1 &&
                        !isNaN(_vm.displayDate.percent_9 / _vm.pastDate.percent_9 - 1)
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.percent_9 - _vm.pastDate.percent_9 < 0
                          ? 'red'
                          : 'green',attrs:{"type":_vm.displayDate.percent_9 - _vm.pastDate.percent_9 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])])])])]),_c('div',{staticClass:"ratio second"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"vertical-red"},[_vm._v("|")]),_c('div',{staticClass:"ratio-title"},[_vm._v("25平方公里内")]),_c('div',[_c('Tooltip',{attrs:{"placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 以区域为中心，周边25平方公里的格子内，客流占比等情况 ")]),_c('Icon',{attrs:{"type":"md-help-circle"}})],1)],1)]),_c('div',{staticClass:"ratio-content"},[_c('div',[_c('div',[_c('div',[_vm._v("客流总数占比")]),_c('div',[_vm._v(" "+_vm._s(_vm.displayDate && _vm.displayDate.pop_ratio_25 && _vm.displayDate.pop_ratio_25 !== -1 ? _vm.displayDate.pop_ratio_25 + '%' : '暂无数据')+" ")]),_c('div',[(
                      _vm.displayDate &&
                        _vm.displayDate.pop_ratio_25 &&
                        _vm.displayDate.pop_ratio_25 !== -1 &&
                        _vm.pastDate &&
                        _vm.pastDate.pop_ratio_25 &&
                        _vm.pastDate.pop_ratio_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_ratio_25 / _vm.pastDate.pop_ratio_25)
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.displayDate &&
                        _vm.displayDate.pop_ratio_25 &&
                        _vm.displayDate.pop_ratio_25 !== -1 &&
                        _vm.pastDate &&
                        _vm.pastDate.pop_ratio_25 &&
                        _vm.pastDate.pop_ratio_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_ratio_25 / _vm.pastDate.pop_ratio_25)
                    )?_c('div',{class:_vm.displayDate.pop_ratio_25 - _vm.pastDate.pop_ratio_25 < 0
                        ? 'red'
                        : 'green'},[_vm._v(" "+_vm._s(( (_vm.displayDate.pop_ratio_25 / _vm.pastDate.pop_ratio_25 - 1) * 100 ).toFixed(2) + '%')+" ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.displayDate.pop_ratio_25 &&
                        _vm.displayDate.pop_ratio_25 !== -1 &&
                        _vm.pastDate &&
                        _vm.pastDate.pop_ratio_25 &&
                        _vm.pastDate.pop_ratio_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_ratio_25 / _vm.pastDate.pop_ratio_25)
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.pop_ratio_25 - _vm.pastDate.pop_ratio_25 < 0
                          ? 'red'
                          : 'green',attrs:{"type":_vm.displayDate.pop_ratio_25 - _vm.pastDate.pop_ratio_25 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])]),_c('div',[_c('div',[_vm._v("到访人数")]),(
                    _vm.displayDate &&
                      _vm.displayDate.pop_num_25 &&
                      _vm.displayDate.pop_num_25 !== -1
                  )?_c('div',{attrs:{"id":"box7"}},[_vm._v(" "+_vm._s(_vm.displayDate.pop_num_25)+" ")]):_c('div',[_vm._v("暂无数据")]),_c('div',[(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_num_25 &&
                        _vm.displayDate.pop_num_25 !== -1 &&
                        _vm.pastDate.pop_num_25 &&
                        _vm.pastDate.pop_num_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_num_25 / _vm.pastDate.pop_num_25)
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_num_25 &&
                        _vm.displayDate.pop_num_25 !== -1 &&
                        _vm.pastDate.pop_num_25 &&
                        _vm.pastDate.pop_num_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_num_25 / _vm.pastDate.pop_num_25)
                    )?_c('div',{class:_vm.displayDate.pop_num_25 - _vm.pastDate.pop_num_25 < 0
                        ? 'red'
                        : 'green'},[_vm._v(" "+_vm._s(( (_vm.displayDate.pop_num_25 / _vm.pastDate.pop_num_25 - 1) * 100 ).toFixed(2))+"% ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_num_25 &&
                        _vm.displayDate.pop_num_25 !== -1 &&
                        _vm.pastDate.pop_num_25 &&
                        _vm.pastDate.pop_num_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_num_25 / _vm.pastDate.pop_num_25)
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.pop_num_25 - _vm.pastDate.pop_num_25 < 0
                          ? 'red'
                          : 'green',attrs:{"type":_vm.displayDate.pop_num_25 - _vm.pastDate.pop_num_25 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])]),_c('div',[_c('div',[_vm._v("居住人口总数")]),(
                    _vm.displayDate &&
                    _vm.displayDate.pop_base_25 &&
                    _vm.displayDate.pop_base_25 !== -1
                  )?_c('div',{attrs:{"id":"box8"}},[_vm._v(" "+_vm._s(_vm.displayDate.pop_base_25)+" ")]):_c('div',[_vm._v("暂无数据")]),_c('div',[(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_base_25 &&
                        _vm.displayDate.pop_base_25 !== -1 &&
                        _vm.pastDate.pop_base_25 &&
                        _vm.pastDate.pop_base_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_base_25 / _vm.pastDate.pop_base_25)
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_base_25 &&
                        _vm.displayDate.pop_base_25 !== -1 &&
                        _vm.pastDate.pop_base_25 &&
                        _vm.pastDate.pop_base_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_base_25 / _vm.pastDate.pop_base_25)
                    )?_c('div',{class:_vm.displayDate.pop_base_25 - _vm.pastDate.pop_base_25 < 0
                        ? 'red'
                        : 'green'},[_vm._v(" "+_vm._s(( (_vm.displayDate.pop_base_25 / _vm.pastDate.pop_base_25 - 1) * 100 ).toFixed(2))+"% ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.pop_base_25 &&
                        _vm.displayDate.pop_base_25 !== -1 &&
                        _vm.pastDate.pop_base_25 &&
                        _vm.pastDate.pop_base_25 !== -1 &&
                        !isNaN(_vm.displayDate.pop_base_25 / _vm.pastDate.pop_base_25)
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.pop_base_25 - _vm.pastDate.pop_base_25 < 0
                          ? 'red'
                          : 'green',attrs:{"type":_vm.displayDate.pop_base_25 - _vm.pastDate.pop_base_25 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])]),_c('div',[_c('div',[_vm._v("市场渗透率")]),_c('div',[_vm._v(" "+_vm._s(_vm.displayDate && _vm.displayDate.percent_25 && _vm.displayDate.percent_25 !== -1 ? _vm.displayDate.percent_25 + '%' : '暂无数据')+" ")]),_c('div',[(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.percent_25 &&
                        _vm.pastDate.percent_25 &&
                        _vm.displayDate.percent_25 !== -1 &&
                        _vm.pastDate.percent_25 !== -1 &&
                        !isNaN(_vm.displayDate.percent_25 / _vm.pastDate.percent_25)
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.compareDate)+" ")]):_vm._e(),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.percent_25 &&
                        _vm.pastDate.percent_25 &&
                        _vm.displayDate.percent_25 !== -1 &&
                        _vm.pastDate.percent_25 !== -1 &&
                        !isNaN(_vm.displayDate.percent_25 / _vm.pastDate.percent_25)
                    )?_c('div',{class:_vm.displayDate.percent_25 - _vm.pastDate.percent_25 < 0
                        ? 'red'
                        : 'green'},[_vm._v(" "+_vm._s(( (_vm.displayDate.percent_25 / _vm.pastDate.percent_25 - 1) * 100 ).toFixed(2) + '%')+" ")]):_c('div',[_vm._v(_vm._s(_vm.compareTips))]),(
                      _vm.displayDate &&
                        _vm.pastDate &&
                        _vm.displayDate.percent_25 &&
                        _vm.pastDate.percent_25 &&
                        !isNaN(_vm.displayDate.percent_25 / _vm.pastDate.percent_25)
                    )?_c('div',[_c('Icon',{class:_vm.displayDate.percent_25 - _vm.pastDate.percent_25 < 0
                          ? 'red'
                          : 'green',attrs:{"type":_vm.displayDate.percent_25 - _vm.pastDate.percent_25 > 0
                          ? 'md-arrow-dropup'
                          : 'md-arrow-dropdown'}})],1):_vm._e()])])])])])])]},proxy:true},{key:"slotEcharts",fn:function(){return [_c('div',{staticClass:"for-echarts"},[_c('div',[_vm._v("客流总数占比趋势图")]),_c('div',{staticClass:"echarts-content",on:{"mouseenter":function($event){return _vm.buriedPointHandler('passenger_flow_proportion_trend_enter')}}},[_c('vue-echarts',{ref:"echarts",staticClass:"echarts",style:({height:_vm.echartsHeight}),attrs:{"option":_vm.totalTourist,"autoresize":true}})],1)])]},proxy:true}])}),_c('tdesign-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }